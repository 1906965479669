<template >
    <div class="card mb-0">
        <div class="card-body">
            <a href="javascript:void(0);" class="brand-logo">

                <img style="width: 230px; max-height: 120px;" class="text-center"
                    src="https://dev.phoenixgn.com/logo-white.png" alt="">
            </a>

            <h4 class="card-title mb-1 ">Verify your email👋</h4>

            <p>Your OTP has been sent to your email. If you experience difficulty getting an email please contact admin @ <a href="https://wa.link/38jxiz">https://wa.link/38jxiz</a></p>


            <div class="auth-login-form mt-2">
                <div class="form-group">
                    <label for="login-email" class="form-label">Enter OTP</label>
                    <input style="letter-spacing: 2em; outine: 0px;" type="text" class=" form-control text-center "
                        maxlength="6" id="login-email" v-model="otp" placeholder="* * * * * "
                        aria-describedby="login-email" tabindex="1" autofocus />
                </div>




                <div class="form-group">
                    <div class="custom-control custom-checkbox">

                        <span @click="resendOtp()"> Resend OTP </span>
                    </div>
                </div>
                <button @click="verify_otp()" class="btn btn-primary btn-block" tabindex="4">Submit</button>
            </div>

            <!-- <p class="text-center mt-2">
                <span>New to Phoenixgn?</span>
                <router-link to="/register">
                    <span>Create an account</span>
                </router-link>
            </p> -->


        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            fullPage: false,
            email: '',
            otp: '',
            userData: []
        }
    },

    methods: {

        verify_otp() {


      

            let loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: true,
                color: '#7367F0',
                loader: 'dots',
                onCancel: this.onCancel,
            });


            // // simulate AJAX
            // setTimeout(() => {
            //   loader.hide()
            // },5000) 

            axios({
                method: "post",
                url: process.env.VUE_APP_URL + '/api/verify_otp',
                data: {
                    otp: this.otp,
                },
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' +localStorage.getItem('user_token')
                },
            }).then((response) => {

                console.log(response)


                if (response.data.user_data) {

                    loader.hide()

                    this.$vToastify.success({
                        'body': 'Email Verified',
                        'iconEnabled': true,
                        'defaultTitle': false,
                        'hideProgressbar': true
                    });

                    return this.$router.push('/user/home')

                }else{

                    loader.hide()


                    this.$vToastify.success({
                        'body': 'Otp Not Valid',
                        'iconEnabled': true,
                        'defaultTitle': false,
                        'hideProgressbar': true
                    });

                 

                }




            })
                .catch((response) => {

                    loader.hide()

                    this.$vToastify.error({
                        'body': 'Invalid Credentials',
                        'iconEnabled': true,
                        'defaultTitle': false,
                        'hideProgressbar': true
                    });

                    console.log(response)

                    return this.$router.push('/verify')


                })






        },

        resendOtp() {
           
            axios({
                method: 'post',
                url: process.env.VUE_APP_URL + '/api/resend_otp',
               
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },
            }).then((response) => {
                console.log(response)
                this.$vToastify.success({
                    'body': 'Otp Resent',
                    'iconEnabled': true,
                    'defaultTitle': false,
                    'hideProgressbar': true
                });
            }).catch((err)=>{
                console.log(err)
            })
        }

    },
}
</script>

<style scoped>
.form-control:focus {
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-control {
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}
</style>