<template >
    <div class="card mb-0">
        <div class="card-body">
            <a href="javascript:void(0);" class="brand-logo">
                
                <img style="width: 230px; max-height: 120px;" class="text-center" src="https://api.phoenixgn.com/logo-white.png" alt="">
            </a>

            <h4 class="card-title mb-1">Reset password 👋</h4>

            <div v-show="err" class="alert alert-danger p-1" role="alert">
            Reset link is invalid.
            </div>
          

            <div class="auth-login-form mt-2" >

                <div class="form-group">
                    <div class="d-flex justify-content-between">
                        <label for="login-password">New Password</label>
                        <!-- <a href="page-auth-forgot-password-v1.html">
                            <small>Forgot Password?</small>
                        </a> -->
                    </div>
                    <div class="input-group input-group-merge form-password-toggle">
                        <input class="form-control form-control-merge" :type="passwordAttribute" id="login-password" v-model="password1" tabindex="2" placeholder="" aria-describedby="login-password" />
                        <div @click="togglePasswordVisibility()" class="input-group-append">
                            <span class="input-group-text cursor-pointer"><i class="fa-solid fa-eye"></i></span>
                        </div>
                    </div>
                </div>
             

                <div class="form-group">
                    <div class="d-flex justify-content-between">
                        <label for="login-password">Confirm New Password</label>
                        <!-- <a href="page-auth-forgot-password-v1.html">
                            <small>Forgot Password?</small>
                        </a> -->
                    </div>
                    <div class="input-group input-group-merge form-password-toggle">
                        <input class="form-control form-control-merge" :type="passwordAttribute" id="login-password" v-model="password" tabindex="2" placeholder="" aria-describedby="login-password" />
                        <div @click="togglePasswordVisibility()" class="input-group-append">
                            <span class="input-group-text cursor-pointer"><i class="fa-solid fa-eye"></i></span>
                        </div>
                    </div>
                </div>
                
                <button @click="resetPassword()" class="btn btn-primary btn-block" tabindex="4">Set new password</button>
            </div>

            

        
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            fullPage: false,
            password1: '',
            password: '',
            passwordAttribute: 'password',
            code: '',
            err: false
        }
    },

    methods: {

        togglePasswordVisibility(){

   
       
            if(this.passwordAttribute == 'password'){
                this.passwordAttribute = 'text'
            }else{
                this.passwordAttribute = 'password'
            }

        },

        verifyCode(){

                axios({
                    method: "post",
                    url: process.env.VUE_APP_URL+'/api/verify_reset_code',
                    data: {
                        code: this.$route.params.code,
                  
                    }
                })
                .then((response)=>{

       
                    console.log(response)


                    // return this.$router.push('/login')
                    

                })
                .catch((response)=>{
                    
                    //   loader.hide()
                        // this.$vToastify.error({
                        //     'body': 'Invalid Credentials',
                        //     'iconEnabled': true,
                        //     'defaultTitle': false,
                        //     'hideProgressbar': true                                        
                        // });

                        this.err=true

                         console.log(response)

                        // return this.$roter.push('/login')
                

                })

                


           

        },

        resetPassword(){

            axios({
                method: 'post',
                url: process.env.VUE_APP_URL+'/api/reset_user_password',
                data:{
                    password: this.password,
                    code: this.$route.params.code
                }
            }).then((res)=>{

                console.log(res)

                  this.$vToastify.success({
                            'body': 'Password reset successful',
                            'iconEnabled': true,
                            'defaultTitle': false,
                            'hideProgressbar': true                                        
                        });

                return this.$router.push('/login')


            }).catch((err)=>{

                console.log(err)

            })
        }
    },

    mounted() {

        this.verifyCode()
    },
}
</script>