<template >
    <div class="card mb-0">
        <div class="card-body">
            <a href="javascript:void(0);" class="brand-logo">
                
                <img style="width: 230px; max-height: 120px;" class="text-center" src="https://api.phoenixgn.com/logo-white.png" alt="">
            </a>

            <h4 class="card-title mb-1">Please Enter Email</h4>
            <p class="card-text mb-2">A password reset link will be sent to your registered email</p>
            <div v-show="msg" class="alert alert-info" role="alert">
            Please check your email to proceed
            </div>

            <div class="auth-login-form mt-2" >
                <div class="form-group">
                    <label for="login-email" class="form-label">Email</label>
                    <input type="text" class="form-control" id="login-email" v-model="email" placeholder="john@example.com" aria-describedby="login-email" tabindex="1" autofocus />
                </div>

                <div class="form-group d-none">
                    <div class="d-flex justify-content-between">
                        <label for="login-password">Password</label>
                        <!-- <a href="page-auth-forgot-password-v1.html">
                            <small>Forgot Password?</small>
                        </a> -->
                    </div>
                    <div class="input-group input-group-merge form-password-toggle">
                        <input class="form-control form-control-merge" :type="passwordAttribute" id="login-password" v-model="password" tabindex="2" placeholder="" aria-describedby="login-password" />
                        <div @click="togglePasswordVisibility()" class="input-group-append">
                            <span class="input-group-text cursor-pointer"><i class="fa-solid fa-eye"></i></span>
                        </div>
                    </div>
                </div>
                <div class="form-group d-none">
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" id="remember-me" tabindex="3" />
                        <label class="custom-control-label" for="remember-me"> Remember Me </label>
                    </div>
                </div>
                <button @click="sendResetLink()" class="btn btn-primary btn-block" tabindex="4">Submit</button>
            </div>

          

        
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            fullPage: false,
            email: '',
            password: '',
            passwordAttribute: 'password',
            msg: false
        }
    },

    methods: {

        togglePasswordVisibility(){

   
       
            if(this.passwordAttribute == 'password'){
                this.passwordAttribute = 'text'
            }else{
                this.passwordAttribute = 'password'
            }

        },

        sendResetLink(){

                let loader = this.$loading.show({
                  // Optional parameters
                  container: this.fullPage ? null : this.$refs.formContainer,
                  canCancel: true,
                  color: '#7367F0',
                  loader: 'bars',
                  onCancel: this.onCancel,
                  opacity: 0.3
                });

              
                // // simulate AJAX
                // setTimeout(() => {
                //   loader.hide()
                // },5000) 

                axios({
                    method: "post",
                    url: process.env.VUE_APP_URL+'/api/reset_password',
                    data: {
                        email: this.email,
                    }
                })
                .then((response)=>{

                    loader.hide()

           
                    console.log(response),

                    this.msg = true



                
                 

                    // return this.$router.push('/reset-password')

                   

                })
                .catch((err)=>{

                    console.log(err)
                    
                      loader.hide()
                        this.$vToastify.error({
                            'body': 'Email not found',
                            'iconEnabled': true,
                            'defaultTitle': false,
                            'hideProgressbar': true                                        
                        });


                        
                

                })

                


           

        }
    },
}
</script>